/**
 * Created by oliver on 06.03.18.
 */
module gmtApp.database {
    const moduleName = 'gmtApp.database';

    export type DexieFactory = <T extends dexie.Dexie>(...args: ConstructorParameters<typeof dexie.Dexie>) => T;

    angular.module(moduleName, ['gmtApp.settings'])
        .factory('dexieFactory', ['$window', function ($window: angular.IWindowService): DexieFactory {
            return function <T extends dexie.Dexie> (...args: ConstructorParameters<typeof dexie.Dexie>): T {
                return new $window.Dexie(...args) as T;
            }
        }]);
}